<script setup>

useHead({
  title: "Vehis.pl - atrakcyjny leasing samochodów nowych i używanych",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
  link: [
    {name: 'robots', content: 'noindex, follow'},
    {rel: 'canonical', href: 'https://vehis.pl'}
  ]
});

const backend = useBackend();
const route = useRoute();
const favorites = useFavorites();

const {onChange, leasingDrawerOpened} = useLeasingParams();
const {onWindowEvent} = useWindowEvents();
const {onMouseUp} = useMouseEvents();

const refCarCompare = ref();
const refCarCompareHeaders = ref();

const refCard = ref([]);
const visibleAll = ref(false);


onMounted(async () => {
  await favorites.loadCars();
})

const {data: equipmentData} = await useAsyncData(async () => {
  const equip = await backend.getEquipmentDictionary();
  return equip.data.data;
})

const equipmentDict = computed(() => {
  const result = new Map;
  Object.keys(equipmentData.value).forEach(group => {
    Object.keys(equipmentData.value[group]).forEach(id => {
      const name = equipmentData.value[group][id];
      let exists = false;
      favorites.cars.value.forEach(car => {
        if (!exists) {
          if (!!car.equipment.find(i => i == id)) {
            exists = true;
          }
        }
      });

      if (exists && id !== '9999') {
        result.set(id, name)
      }
    })
  })

  return new Map([...result.entries()].sort((a, b) => String(a[1]).localeCompare(b[1], 'pl')));
})

const connectSlideControls = (type) => {
  if (type === 'main' && dragEventOwner === null) {
    dragEventOwner = 'main';
  }
  if (type !== dragEventOwner) return false;
  switch (type) {
    case 'main':
      refCarCompareHeaders.value.$el.scrollLeft = refCarCompare.value.$el.scrollLeft;
      break;
    case 'headers':
      refCarCompare.value.$el.scrollLeft = refCarCompareHeaders.value.$el.scrollLeft;
      break;
  }
  return true;
}

let dragEventOwner = null;
const setDragOwner = (type) => {
  dragEventOwner = type;
}

const showHeaders = ref(false);
onWindowEvent('scroll', (e) => {
  if (!refCarCompare.value?.$el) return;
  const box = refCarCompare.value.$el.getBoundingClientRect();
  showHeaders.value = box.y <= -350;
})

const openFinancingDrawer = () => {
  leasingDrawerOpened.value = true;
}

onMouseUp(() => {
  dragEventOwner = null;
})

</script>

<template>
  <Container no-paddings class="relative z-40">
    <div class="w-full flex justify-end pt-6" v-if="favorites.count.value">
      <ButtonToggle class="text-sm font-light text-azure !fill-azure"
                    v-model:toggled="leasingDrawerOpened"
                    with-chevron>
        Dopasuj ratę
      </ButtonToggle>
    </div>
    <Accordion ref="refFinancing" :expanded="leasingDrawerOpened" v-if="favorites.count.value">
      <div class="py-2">
        <CarFinancing />
      </div>
    </Accordion>
    <MainContentHeader v-if="favorites.count.value" :counter="favorites.count.value" class="!py-4 md:w-full">Łatwo i wygodnie porównaj wybrane samochody</MainContentHeader>
    <MainContentHeader v-else class="pt-12 !pb-4 w-full !text-center">Jak działa porównywarka samochodów</MainContentHeader>
  </Container>
  <Container no-paddings class="relative">
    <div v-if="!favorites.count.value">
      <ContentSteps class="max-w-[1060px] mx-auto">
        <ContentStep>
          <b>Dodaj min. 2 samochody</b> z naszej oferty wybierając ulubioną markę lub korzystając z wyszukiwarki zaawansowanej.
        </ContentStep>
        <ContentStep>
          <b>Porównaj</b> parametry techniczne, ofertę finansowania, a nawet szczegółowe wyposażenie.
        </ContentStep>
        <ContentStep>Wybierz odpowiedni samochód dla siebie i kliknij w
          <b>“Przejdź do oferty”</b> aby zobaczyć podsumowanie.
        </ContentStep>
      </ContentSteps>

      <div class="flex justify-center items-center pb-20">
        <div class="w-[300px]">
          <CarAddToCompare />
        </div>
      </div>
    </div>
    <LayoutColumns v-else>
      <LayoutMainColumn noflex>
        <div class="sticky top-[110px] -ml-4 pl-4 pb-2 -mb-5 z-20 flex justify-center items-center md:gap-[142px] xl:gap-[150px] bg-gradient-to-b from-80% from-white to-transparent">
          <div class="w-6 hidden md:block" :class="{'opacity-30':!visibleAll}">
            <IconChevronLeft class="!w-3.5 !h-3.5 cursor-pointer" @click="() => refCarCompare.prevSlide()" />
          </div>
          <div class="grow max-w-[320px] md:max-w-none">
            <ScrollBarHorizontal :scrolledElement="refCarCompare" @visibilityChange="(v) => visibleAll = v" />
          </div>
          <div class="w-6 hidden md:block" :class="{'opacity-30':!visibleAll}">
            <IconChevronRight class="!w-3.5 !h-3.5 cursor-pointer" @click="() => refCarCompare.nextSlide()" />
          </div>
        </div>

        <div class="sticky top-[142px] z-20 h-1 opacity-0 transition-opacity pointer-events-none" :class="{'opacity-100 !pointer-events-auto': showHeaders}">
          <SlideControl ref="refCarCompareHeaders"
                        class="bg-gradient-to-b from-80% from-white to-transparent !gap-4"
                        :size=280
                        :gap=16
                        @scroll="connectSlideControls('headers')"
                        @mousedown="setDragOwner('headers')"
                        @touchstart="setDragOwner('headers')">
            <SlideItem v-for="(car, idx) in favorites.cars.value" :index="idx" thin>
              <div class="bg-white w-full font-normal text-sm -ml-2 px-2 select-none"
                   :class="{'border-l border-l-gray-400' : idx > 0}">
                <div class="overflow-x-hidden overflow-ellipsis whitespace-nowrap border-b border-b-gray-400 pb-2 mb-2">
                  {{ car?.versionName }}
                </div>
                <div class="flex">
                  <div class="w-[212px] h-[90px] flex justify-center items-start overflow-hidden" @dragstart.prevent="">
                    <NuxtImg v-if="car?.image || car?.images[0]" :src="car?.image || car?.images[0]" class="h-[90px] z-10" :alt="car?.brandName ? (car?.brandName + ' ' + car?.versionName) : ''" loading="lazy" />
                  </div>
                  <div class="shrink-0 flex flex-col items-end">
                    <div class="text-xs font-extralight">Twoja rata
                      <EffectNetGrossIndicator />
                    </div>
                    <div class="text-azure">
                      <EffectOdomoeter class="text-3xl font-normal" :value="car.installment" />
                      <span class="text-sm font-extralight"> zł</span>
                    </div>
                    <div class="w-full">
                      <ButtonRounded color="gray" class="w-full" :href="{name: 'offer', params: {id: car?.group_id}}" small>Oferta</ButtonRounded>
                    </div>
                  </div>

                </div>
              </div>
            </SlideItem>
            <SlideItem>
              <CarAddToCompare />
            </SlideItem>
          </SlideControl>
        </div>

        <SlideControl ref="refCarCompare" class="!gap-4" :size=280 :gap=16
                      @scroll="connectSlideControls('main')"
                      @mousedown="setDragOwner('main')"
                      @touchstart="setDragOwner('main')">
          <SlideItem v-for="(car, idx) in favorites.cars.value" :index="idx" thin>

            <CarCard :ref="(el) => refCard.push(el)"
                     :car="car"
                     @adjust="openFinancingDrawer"
                     shrink-on-scroll
                     thin offer-view />
            <CarEquipment :car="car"
                          :dictionary="equipmentDict"
                          :control="idx === 0"
            />
          </SlideItem>
          <SlideItem thin>
            <CarAddToCompare />
          </SlideItem>
        </SlideControl>

      </LayoutMainColumn>
      <LayoutSideColumn class="!pl-4 !z-30">
        <FormContact class=" !z-30" docking />
      </LayoutSideColumn>
    </LayoutColumns>
  </Container>
</template>
