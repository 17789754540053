<script setup>

</script>

<template>
  <NuxtLink :to="{name: 'index'}" class="flex flex-col items-center justify-center rounded-lg overflow-hidden shadow-box select-none bg-white pointer-events-auto min-w-[280px] h-[175px] font-light cursor-pointer">
    <div class="border border-black rounded-full w-10 h-10 flex flex-col justify-center items-center mb-2">
      <div class="h-[14px] border-l border-l-black -mt-[7px]"></div>
      <div class="w-[14px] border-t border-t-black -mt-[7px]"></div>
    </div>
    <div>Dodaj samochód</div>
    <div>do porównania</div>
  </NuxtLink>
</template>
